import React from "react"

import ExperimentsData from '../../data/experiments'
import Experiment from "./Experiment"

const Experiments = () => {
  const data = ExperimentsData();
  return (
    <section className="Lab outer-padding-x outer-padding-y">
      <div className="Lab__intro max-width">
        <h1 className="fadein">Lab</h1>
        <p className="has-big-font-size fadein">Experiments, playground, bits and bobs from unreleased projects</p>
      </div>
      <div className="Experiments">
        <div className="grid-x grid-padding-x--big">
          {data.map((experiment) =>
            <Experiment key={experiment.id} video={experiment.video} title={experiment.title} description={experiment.description} url={experiment.url} tech={experiment.technologies} />
          )}
        </div>
      </div>
    </section>
  )
}

export default Experiments