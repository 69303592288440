import typePhysicsVideo from '../images/experiments/datasapiens-typephysics.mp4'
import foodDetectionVideo from '../images/experiments/food-detection.mp4'
import tadaShooterVideo from '../images/experiments/tadashooter.mp4'

const ExperimentsData = () => {

  const experiments = [
    {
      id: 1,
      video: typePhysicsVideo,
      title: 'Type physics simulator',
      description: 'Web application for generating perfomant type-physics simulation with video export using native JS and WebAssembly',
      url: 'https://datasapiens.life/',
      technologies: 'WebAssembly, JavaScript, Canvas, WebWorkers, Media encoder, ffmpeg',
    },
    {
      id: 2,
      video: foodDetectionVideo,
      title: 'Food detection app',
      description: 'Web application food detection prototype using maching learning, canvas and mobile camera. Made for registering foods at hand with matching recipes.',
      url: 'https://kollektiv.space/food-detection/',
      technologies: 'MachineLearning, TensorFlow, IBM Cloud, React.js, Canvas, PWA',
    },
    {
      id: 3,
      video: tadaShooterVideo,
      title: 'Confetti shooter',
      description: 'Confetti 404 shooter with Matter JS',
      url: 'https://tada.no/fourohfour/',
      technologies: 'Matter.js, Canvas, SVG',
    },
  ]

  return experiments;

}

export default ExperimentsData;