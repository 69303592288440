import React from "react"

const Experiment = (props) => {
  const { video, title, description, url, tech } = props;
  const techArray = tech.split(',');
  return (
    <article className="Experiment cell medium-6 fadein">
      <a href={url} target="_blank" rel="noopener noreferrer">
        <figure className="Experiment__image">
          <video autoPlay muted playsInline loop preload="auto" src={video}></video>
        </figure>
        <h1 className="Experiment__title">{title}</h1>
        <p>{description}</p>
        <ul className="list-unstyled Experiment__tech">
          {techArray.map((tech, i) =>
            <li key={i} className="bg-lightgray">{tech}</li>
          )}
        </ul>
      </a>
    </article>
  )
}

export default Experiment
