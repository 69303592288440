import React from "react"

import SEO from "../components/seo"
import InnerLayout from "../components/InnerLayout"
import InnerWrapper from "../components/InnerWrapper"
import Experiments from "../components/lab/Experiments"

const ContactPage = () => (
  <InnerLayout>
    <SEO title="Lab"/>
    <InnerWrapper>
      <Experiments />
    </InnerWrapper>
  </InnerLayout>
)

export default ContactPage
